<template>
  <v-app-bar
    flat
    color="transparent"
  >
    <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title>Apuestas</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="toggleTheme" v-show="!isDark">
          <v-list-item-title>Modo oscuro</v-list-item-title>
        </v-list-item>

        <v-list-item @click="toggleTheme" v-show="isDark">
          <v-list-item-title>Modo blanco</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { emitter, OPEN_DRAWER } from '../../shared/bus'

export default {
  computed: {
    isDark () {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    openDrawer () {
      emitter.emit(OPEN_DRAWER, { open: true })
    }
  }
}
</script>
