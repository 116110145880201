import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#ff6363',
        secondary: '#543864',
        accent: '#ffbd69'
      }
    }
  }
})
