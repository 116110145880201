import { SERVICES_URL } from '../shared/config'
let token = null

function getHeaders () {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  return myHeaders
}

async function loadToken () {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: '',
    redirect: 'follow'
  }

  await fetch(`${SERVICES_URL}/auth/login`, requestOptions)
    .then(response => response.json())
    .then(body => {
      token = body.token
    })
    .catch(error => {
      console.error(error)
    })
}
// loadToken()

export default {
  setToken (_token) {
    token = _token
  },
  getToken () {
    return token
  },
  loadToken
}
