<template>
  <v-card width="100%">
    <v-card-text>
      <EventHeader :event="event" />
      <v-row>
        <v-col>
          Half Points = {{ bet.odds_18_6.handicap }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Over = {{ bet.odds_18_6.over_od }}
        </v-col>
        <v-col>
          {{ bet.odds_18_6.overAmount }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Under = {{ bet.odds_18_6.under_od }}
        </v-col>
        <v-col>
          {{ bet.odds_18_6.underAmount }}
        </v-col>
      </v-row>

      <v-divider class="my-6"></v-divider>

      <v-row>
        <v-col>
          Total Points = {{ bet.odds_18_3.handicap }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Over = {{ bet.odds_18_3.over_od }}
        </v-col>
        <v-col>
          {{ bet.odds_18_3.overAmount }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Under = {{ bet.odds_18_3.under_od }}
        </v-col>
        <v-col>
          {{ bet.odds_18_3.underAmount }}
        </v-col>
      </v-row>
<!--
      <br />
      {{ bet.odds_18_6 }}
      <br />
      <br />
      {{ bet.odds_18_3 }}
      <br />
      <br />
      {{ bet }}
      -->
    </v-card-text>
  </v-card>
</template>

<script>
import EventHeader from '../../components/Events/EventHeader.vue'

import betsDispatcher from '../../dispatcher/betsDispatcher'

export default {
  data: () => ({
    event: {
      home: {},
      away: {},
      criteria: {},
      league: {},
      odds: {},
      scores: {},
      id: null,
      isReadyToBet: false,
      isVisible: false,
      ss: '',
      time: null
    }
  }),
  props: {
    bet: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    betsDispatcher
      .getEventId({ eventId: this.bet.event_id })
      .then(body => {
        // eslint-disable-next-line eqeqeq
        if (body.success == 1) {
          this.event = body.results[0]
        }
      })
      .catch(error => {
        console.error(error)
      })
  },
  components: {
    EventHeader
  }
}
</script>
