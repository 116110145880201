import userDispatcher from '../dispatcher/userDispatcher'
const SERVER = 'https://graph.bets.strategio.cloud/v1/graphql'

function getHeaders () {
  const myHeaders = new Headers()
  // myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Content-Type', 'application/json')

  const token = userDispatcher.getToken()
  if (token) {
    myHeaders.append('Authorization', `Bearer ${token}`)
  }
  return myHeaders
}

export const makeQuery = ({ query, variables = {} }) => {
  const graphql = JSON.stringify({ query, variables })
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: graphql,
    redirect: 'follow'
  }

  return fetch(SERVER, requestOptions)
    .then(response => response.json())
    .catch(error => {
      // eslint-ignore-next-line
      console.log('GRAPH error', error)
    })
}
