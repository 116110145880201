<template>
  <v-row v-if="filteredEvents.length > 0" :align="'center'">
    <v-col
      v-for="(e, $index) in filteredEvents"
      :key="$index"
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
    >
      <Event :event="e" />
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col :align="'center'">
      No hay eventos visibles<span v-show="events.length">, pero si hay
        <v-btn text color="accent" @click="showAllEvents">eventos ocultos ({{ events.length }})</v-btn>
      </span>
    </v-col>
  </v-row>
</template>

<script>
import Event from './Event.vue'
import eventsDispatcher from '../../dispatcher/eventsDispatcher'
import { multisort } from '../../shared/utils'
import { emitter, TOGGLE_VISIBLE_EVENTS, SHOW_ALL_VISIBLE_EVENTS } from '../../shared/bus'

let RELOAD_REF = null
export default {
  data: () => ({
    events: [],
    showOnlyVisible: true
  }),
  computed: {
    filteredEvents () {
      if (this.showOnlyVisible) {
        return this.events.filter(e => e.isVisible === true)
      } else {
        return this.events
      }
    }
  },
  mounted () {
    emitter.on(TOGGLE_VISIBLE_EVENTS, this.toggleShowOnlyVisible)
    RELOAD_REF = window.setInterval(() => {
      this.loadEvents()
    }, 5000)
    this.loadEvents()
  },
  beforeDestroy () {
    emitter.off(TOGGLE_VISIBLE_EVENTS, this.toggleShowOnlyVisible)
    window.clearInterval(RELOAD_REF)
  },
  methods: {
    showAllEvents () {
      this.showOnlyVisible = false
      emitter.emit(SHOW_ALL_VISIBLE_EVENTS, {})
    },
    toggleShowOnlyVisible ({ showOnlyVisible = true }) {
      this.showOnlyVisible = showOnlyVisible
    },
    loadEvents () {
      eventsDispatcher.getEvents()
        .then(result => {
          result.sort(
            multisort({
              sortBy: [{
                prop: 'isVisible',
                isNumeric: true,
                direction: -1
              }, {
                prop: 'isReadyToBet',
                isNumeric: true,
                direction: -1
              }]
            })
          )
          this.events = result
        })
    }
  },
  components: {
    Event
  }
}
</script>
