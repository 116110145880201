<template>
  <div>
    <BetsAppBar />
    <v-container>
      <BetsList />
    </v-container>
    <PlaceBetForm />
  </div>
</template>

<script>
import BetsAppBar from '../components/AppBars/BetsAppBar.vue'
import PlaceBetForm from '../components/Dialogs/PlaceBetForm.vue'
import BetsList from '../components/Bets/BetsList.vue'

export default {
  components: {
    BetsAppBar,
    PlaceBetForm,
    BetsList
  }
}
</script>
