<template>
  <v-snackbar
    v-model="snackbar"
    right
    top
  >
    <v-icon v-show="currentErrorLevel === errorLevel.ERROR" color="error">mdi-alert-decagram</v-icon>
    <v-icon v-show="currentErrorLevel === errorLevel.OK" color="success">mdi-check</v-icon>
    <v-icon v-show="currentErrorLevel === errorLevel.WARNING" color="warning">mdi-alert</v-icon>
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { emitter, OPEN_MESSAGE_SNACKBAR } from '../../shared/bus'
import { ERROR_LEVEL } from '../../shared/config'
export default {
  data: () => ({
    snackbar: false,
    text: '',
    currentErrorLevel: 1,
    errorLevel: { ...ERROR_LEVEL }
  }),
  mounted () {
    emitter.on(OPEN_MESSAGE_SNACKBAR, this.open)
  },
  beforeDestroy () {
    emitter.on(OPEN_MESSAGE_SNACKBAR, this.open)
  },
  methods: {
    open ({ message = 'ok', errorLevel = 1 }) {
      this.text = message
      this.snackbar = true
      this.currentErrorLevel = errorLevel
    }
  }
}
</script>
