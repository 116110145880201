<template>
  <v-row>
    <!-- <v-col cols="12">
      {{ title }}
    </v-col> -->
    <v-col cols="12">
      <OddSelector
        name="Over"
        :value="odd.over_od"
        :isSelected="didSelected && isOverSelected"
        @click.native="selectOdd(true)"
        :handicap="odd.handicap"
        :betAmount="betAmount"
      />
    </v-col>
    <v-col cols="12">
      <OddSelector
        name="Under"
        :value="odd.under_od"
        :isSelected="didSelected && !isOverSelected"
        @click.native="selectOdd(false)"
        :handicap="odd.handicap"
        :betAmount="betAmount"
      />
    </v-col>
    <v-col cols="12" v-show="didSelected">
      <v-btn-toggle
        v-model="amountIndex"
        @change="changeAmount"
      >
        <v-btn outlined color="primary" v-for="(val, $index) in defaultValues" :key="$index">
          <span style="align-items: end;">
            <span class="text-caption">{{ currency }}</span> {{ val }}
          </span>
        </v-btn>
        <v-text-field
          @click="amountIndex = null"
          @focus="amountIndex = null"
          v-model="txtAmount"
          :prefix="currency"
          height="48"
          outlined
          dense
          hide-details
          full-width
          type="Number"
          autocomplete="false"
          @keyup="changeAmount"
        />
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
import OddSelector from './OddSelector.vue'
import { DEFAULT_CURRENCY } from '../../../shared/config'

export default {
  data: () => ({
    didSelected: false,
    currency: DEFAULT_CURRENCY,
    amountIndex: null,
    txtAmount: null,
    defaultValues: [10, 100, 800],
    isOverSelected: true,
    betAmount: null
  }),
  methods: {
    getAmount () {
      if (this.amountIndex === null) {
        return this.txtAmount
      } else {
        return this.defaultValues[this.amountIndex]
      }
    },
    selectOdd (val) {
      this.didSelected = true
      this.isOverSelected = val
    },
    changeAmount () {
      if (this.amountIndex !== null) {
        this.betAmount = this.defaultValues[this.amountIndex]
      } else {
        if (this.txtAmount && this.txtAmount.trim() !== '0' && this.txtAmount.trim() !== '') {
          const intAmount = Number(this.txtAmount)

          if (isNaN(intAmount)) {
            this.betAmount = null
          } else {
            console.log(intAmount)
            this.betAmount = intAmount
          }
        } else {
          this.betAmount = null
        }
      }
    }
  },
  watch: {
    isOverSelected (newValue) {
      this.onChange({ isOver: newValue, betAmount: this.betAmount })
    },
    betAmount (newValue) {
      this.onChange({ isOver: this.isOverSelected, betAmount: newValue })
    }
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    odd: {
      type: Object,
      default: () => ({
        handicap: '0',
        over_od: '0',
        under_od: '0'
      })
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    OddSelector
  }
}
</script>
