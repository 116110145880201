<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    max-width="450"
    scrollable
  >
    <v-card>
      <v-card-text class="pt-8">
        <EventHeader :event="event" />

        <v-row>
          <v-col>
            <v-tabs
              v-model="tabs"
              centered
              fixed-tabs
            >
              <v-tab v-if="lastHalfPointsOdd && lastHalfPointsOdd.handicap">
                Half Points
              </v-tab>
              <v-tab v-if="lastTotalsPointsOdd && lastTotalsPointsOdd.handicap">
                Totals Points
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row>
          <v-tabs-items v-model="tabs">
            <v-tab-item v-if="lastHalfPointsOdd && lastHalfPointsOdd.handicap">
              <PlaceBetDefaultValue
                ref="over_18_6"
                title="Half Points"
                :odd="lastHalfPointsOdd"
                :onChange="betHalfChange"
              />
            </v-tab-item>
            <v-tab-item v-if="lastTotalsPointsOdd && lastTotalsPointsOdd.handicap">
              <PlaceBetDefaultValue
                ref="over_18_3"
                title="Totals Points"
                :odd="lastTotalsPointsOdd"
                :onChange="betTotalChange"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>

        <v-row>
          <v-col>
            <v-divider style="border-style: dashed; border-width: 1px;"></v-divider>
          </v-col>
        </v-row>

        <RowTotal
          v-show="selectedHalfHandicap && selectedHalfOdd && betAmounts.half.amount"
          betType="Half"
          :oddType="betAmounts.half.type"
          :handicap="selectedHalfHandicap"
          :amount="betAmounts.half.amount"
          :odd="selectedHalfOdd"
        />

        <RowTotal
          v-show="selectedTotalHandicap && selectedTotalOdd && betAmounts.total.amount"
          betType="Total"
          :oddType="betAmounts.total.type"
          :handicap="selectedTotalHandicap"
          :amount="betAmounts.total.amount"
          :odd="selectedTotalOdd"
        />

      </v-card-text>

      <v-card-actions class="text-center">
        <v-btn text @click="dialog = false">Cerrar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveBet" v-show="totalBetAmount > 0">
          Guardar {{ currency }} {{ betAmounts.half.amount + betAmounts.total.amount }}
          <v-icon>mdi-arrow-right-bold</v-icon>
          {{ currency }} {{ totalBetAmount }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PlaceBetDefaultValue from './PlaceBetForm/PlaceBetDefaultValue.vue'
import RowTotal from './PlaceBetForm/TotalBet/RowTotal.vue'

import EventHeader from '../../components/Events/EventHeader.vue'
import { emitter, OPEN_BET_PLACE_FORM, OPEN_MESSAGE_SNACKBAR } from '../../shared/bus'
import { PLACE_BET } from '../../graphql/bets'
import { makeQuery } from '../../shared/hasura'
import { DEFAULT_CURRENCY, ERROR_LEVEL } from '../../shared/config'
import { round } from '../../shared/utils'

const HALF = 'odds_18_6'
const TOTALS = 'odds_18_3'

export default {
  data: () => ({
    currency: DEFAULT_CURRENCY,
    tabs: null,
    dialog: false,
    event: {
      home: {},
      away: {},
      criteria: {},
      league: {},
      odds: {},
      scores: {},
      timer: {},
      id: null,
      isReadyToBet: false,
      isVisible: false,
      ss: '',
      time: null
    },
    betAmounts: {
      half: {
        amount: 0,
        type: 'over'
      },
      total: {
        amount: 0,
        type: 'over'
      }
    }
  }),
  computed: {
    totalBetAmount () {
      return round({
        number: (this.betAmounts.half.amount * this.selectedHalfOdd) + (this.betAmounts.total.amount * this.selectedTotalOdd),
        decimals: 1
      })
    },
    isValidHalf () {
      const rawOver = this.lastHalfPointsOdd.over_od
      const rawUnder = this.lastHalfPointsOdd.under_od
      const rawHandicap = this.lastHalfPointsOdd.handicap
      return (this.isValid(rawOver) || this.isValid(rawUnder)) && this.isValid(rawHandicap)
    },
    isValidTotals () {
      const rawOver = this.lastTotalsPointsOdd.over_od
      const rawUnder = this.lastTotalsPointsOdd.under_od
      const rawHandicap = this.lastTotalsPointsOdd.handicap
      return (this.isValid(rawOver) || this.isValid(rawUnder)) && this.isValid(rawHandicap)
    },
    selectedHalfOdd () {
      const rawResult = this.betAmounts.half.type === 'over' ? this.lastHalfPointsOdd.over_od : this.lastHalfPointsOdd.under_od
      return this.isValid(rawResult)
    },
    selectedTotalOdd () {
      const rawResult = this.betAmounts.total.type === 'over' ? this.lastTotalsPointsOdd.over_od : this.lastTotalsPointsOdd.under_od
      return this.isValid(rawResult)
    },
    selectedHalfHandicap () {
      const rawResult = this.lastHalfPointsOdd.handicap
      return this.isValid(rawResult)
    },
    selectedTotalHandicap () {
      const rawResult = this.lastTotalsPointsOdd.handicap
      return this.isValid(rawResult)
    },
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },
    isDark () {
      return this.$vuetify.theme.dark
    },
    lastHalfPointsOdd () {
      const odd = this.event.odds[HALF]
      if (odd) {
        const lastOddindex = odd.items.length - 1
        if (lastOddindex > -1) {
          return odd.items[lastOddindex]
        }
        return {}
      }
      return {}
    },
    lastTotalsPointsOdd () {
      const odd = this.event.odds[TOTALS]
      if (odd) {
        const lastOddindex = odd.items.length - 1
        if (lastOddindex > -1) {
          return odd.items[lastOddindex]
        }
        return {}
      }
      return {}
    }
  },
  mounted () {
    emitter.on(OPEN_BET_PLACE_FORM, this.open)
  },
  beforeDestroy () {
    emitter.off(OPEN_BET_PLACE_FORM, this.open)
  },
  methods: {
    saveBet () {
      // const req = {
      //   event_id: '',
      //   odds_18_3: {
      //     { "ss": "61:55", "over_od": "1.800", "add_time": "1603805509", "handicap": "200.5", "time_str": "3 - 06:35", "under_od": "1.869", "overAmount": 10, "underAmount": 10 }
      //   },
      //   odds_18_6: {
      //     { "ss": "47:42", "over_od": "-", "add_time": "1603804619", "handicap": "108.5", "time_str": "3 - 12:00", "under_od": "-", "overAmount": 10, "underAmount": 10 }
      //   }
      // }
      const req = {
        event_id: this.event.id
      }

      req[HALF] = JSON.parse(JSON.stringify(this.lastHalfPointsOdd))
      req[HALF].underAmount = 0
      req[HALF].overAmount = 0
      req[HALF].validOverOdd = this.isValid(this.lastHalfPointsOdd.over_od)
      req[HALF].validUnderOdd = this.isValid(this.lastHalfPointsOdd.under_od)

      if (this.betAmounts.half.type === 'under') {
        req[HALF].underAmount = this.betAmounts.half.amount
      }
      if (this.betAmounts.half.type === 'over') {
        req[HALF].overAmount = this.betAmounts.half.amount
      }

      req[TOTALS] = JSON.parse(JSON.stringify(this.lastTotalsPointsOdd))
      req[TOTALS].underAmount = 0
      req[TOTALS].overAmount = 0
      req[TOTALS].validOverOdd = this.isValid(this.lastTotalsPointsOdd.over_od)
      req[TOTALS].validUnderOdd = this.isValid(this.lastTotalsPointsOdd.under_od)

      if (this.betAmounts.total.type === 'under') {
        req[TOTALS].underAmount = this.betAmounts.total.amount
      }
      if (this.betAmounts.total.type === 'over') {
        req[TOTALS].overAmount = this.betAmounts.total.amount
      }
      // console.log(this.event.id)
      // console.log(this.lastHalfPointsOdd)
      // console.log(this.lastTotalsPointsOdd)
      // console.log(this.betAmounts)
      makeQuery({
        query: PLACE_BET,
        variables: {
          data: req
        }
      }).then(response => {
        if (response.data) {
          emitter.emit(OPEN_MESSAGE_SNACKBAR, { message: 'Apuesta registrada', errorLevel: ERROR_LEVEL.OK })
        } else {
          emitter.emit(OPEN_MESSAGE_SNACKBAR, { message: 'Error. No se registró la apuesta', errorLevel: ERROR_LEVEL.ERROR })
          console.error(response.errors)
        }
      }).catch(error => {
        emitter.emit(OPEN_MESSAGE_SNACKBAR, { message: 'Error fatal. No se registró la apuesta', errorLevel: ERROR_LEVEL.ERROR })
        console.error(error)
      }).finally(() => {
        this.dialog = false
      })

      // console.log(req)
    },
    isValid (value) {
      const result = Number(value)
      if (isNaN(result)) {
        return 0
      }
      return result
    },
    betHalfChange ({ isOver = true, betAmount = null }) {
      if (betAmount) {
        this.betAmounts.half.amount = betAmount
        this.betAmounts.half.type = isOver ? 'over' : 'under'
      } else {
        this.betAmounts.half.amount = null
        this.betAmounts.half.type = isOver ? 'over' : 'under'
      }
    },
    betTotalChange ({ isOver = true, betAmount = null }) {
      if (betAmount) {
        this.betAmounts.total.amount = betAmount
        this.betAmounts.total.type = isOver ? 'over' : 'under'
      } else {
        this.betAmounts.total.amount = null
        this.betAmounts.total.type = isOver ? 'over' : 'under'
      }
    },
    open ({ event = {} }) {
      this.event = { ...event }
      this.dialog = true
    }
  },
  watch: {
    dialog (newValue) {
      if (newValue === false) {
        this.currency = DEFAULT_CURRENCY
        this.tabs = null
        this.dialog = false
        this.event = {
          home: {},
          away: {},
          criteria: {},
          league: {},
          odds: {},
          scores: {},
          timer: {},
          id: null,
          isReadyToBet: false,
          isVisible: false,
          ss: '',
          time: null
        }
        this.betAmounts = {
          half: {
            amount: 0,
            type: 'over'
          },
          total: {
            amount: 0,
            type: 'over'
          }
        }
      }
    }
  },
  components: {
    PlaceBetDefaultValue,
    EventHeader,
    RowTotal
  }
}
</script>

<style lang="scss">
  $red: #e84c3d;
  $grey: #ecedef;
  $black: #343434;

  .cardWrap {
    width: 100%;
    // margin: 3em auto;
    // color: #fff;
    font-family: sans-serif;
  }

  .card {
    color: $black;
    background: linear-gradient(to bottom, #ffd866 0%, #ffd866 26%, #ffd866 26%, #ffd866 100%);
    // background: linear-gradient(to bottom, $red 0%, $red 26%, $grey 26%, $grey 100%);
    height: 11em;
    float: left;
    position: relative;
    padding: 1em;
    margin-top: 40px;
  }

  .cardLeft {
    padding-top: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 70%;
  }

  .cardRight-darkTheme {
    border-left: .18em dashed #1E1E1E;
  }
  .cardRight-darkTheme:before,
  .cardRight-darkTheme:after {
    background: #1E1E1E;
  }

  .cardRight-lightTheme {
    border-left: .18em dashed #fff;
  }
  .cardRight-lightTheme:before,
  .cardRight-lightTheme:after {
    background: #1E1E1E;
  }

  .cardRight {
    width: 30%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: .9em;
      height: .9em;
      // background: #fff;
      border-radius: 50%;
      left: -.5em;
    }
    &:before {
      top: -.4em;
    }
    &:after {
    bottom: -.4em;
    }
  }

  h1 {
    font-size: 1.1em;
    margin-top: 0;
    span {
      font-weight: normal;
    }
  }

  .title, .name, .seat, .time {
    text-transform: uppercase;
    font-weight: normal;
    h2 {
      font-size: .9em;
      color: #525252;
      margin: 0;
    }
    span {
      font-size: .7em;
      color: #a2aeae;
    }
  }

  .title {
    margin: 2em 0 0 0;
  }

  .name, .seat {
    margin: .7em 0 0 0;
  }

  .time {
    margin: .7em 0 0 1em;
  }

  .seat, .time {
    float: left;
  }

  .number {
    text-align: center;
    text-transform: uppercase;
    h3 {
      color: $black;
      margin: 40px 0 0 0;
      font-size: 1.5em;
    }
    span {
      display: block;

      color: #a2aeae;
    }
  }
</style>
