<template>
  <v-app-bar
    flat
    color="transparent"
  >
    <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title>Ganabot</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="toggleTheme" v-show="!isDark">
          <v-list-item-title>Modo oscuro</v-list-item-title>
        </v-list-item>

        <v-list-item @click="toggleTheme" v-show="isDark">
          <v-list-item-title>Modo blanco</v-list-item-title>
        </v-list-item>

        <v-list-item @click="toggleVisible" v-show="showOnlyVisible">
          <v-list-item-title>Ver todos</v-list-item-title>
        </v-list-item>

        <v-list-item @click="toggleVisible" v-show="!showOnlyVisible">
          <v-list-item-title>Ver solo ligas registradas</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { emitter, OPEN_DRAWER, TOGGLE_VISIBLE_EVENTS, SHOW_ALL_VISIBLE_EVENTS } from '../../shared/bus'

export default {
  data: () => ({
    showOnlyVisible: true
  }),
  computed: {
    isDark () {
      return this.$vuetify.theme.dark
    }
  },
  mounted () {
    emitter.on(SHOW_ALL_VISIBLE_EVENTS, this.showAllEvents)
  },
  beforeDestroy () {
    emitter.off(SHOW_ALL_VISIBLE_EVENTS, this.showAllEvents)
  },
  methods: {
    showAllEvents () {
      this.showOnlyVisible = false
    },
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    toggleVisible () {
      this.showOnlyVisible = !this.showOnlyVisible
      emitter.emit(TOGGLE_VISIBLE_EVENTS, { showOnlyVisible: this.showOnlyVisible })
    },
    openDrawer () {
      emitter.emit(OPEN_DRAWER, { open: true })
    }
  }
}
</script>
