<template>
  <v-alert
    text
    color="success"
    style="cursor: pointer;"
    :style="isSelected ? '' : 'filter: grayscale(1);'"
  >
    <v-row no-gutters :align="'center'">
      <v-col cols="2" sm="1">
        <v-scale-transition leave-absolute>
          <v-icon color="success" class="pr-4" :key="1">
            {{ isSelected ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
          </v-icon>
        </v-scale-transition>
      </v-col>
      <v-col>
        <p class="mb-0">
          <span class="text-overline">
            {{ name }} {{ handicap }}
          </span>
          <br v-if="isSelected && betAmount" />
          <span v-if="isSelected && betAmount" class="text-overline">
            {{ currendy }} {{ betAmount }}
          </span>
        </p>
      </v-col>

      <v-col class="text-right">
        <v-chip
          label
          color="success"
        >
          {{ value }}
        </v-chip>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { DEFAULT_CURRENCY } from '../../../shared/config'
export default {
  data: () => ({
    currendy: DEFAULT_CURRENCY
  }),
  props: {
    name: {
      type: String,
      default: () => ''
    },
    value: {
      type: String,
      default: () => 0
    },
    handicap: {
      type: String,
      default: () => 0
    },
    isSelected: {
      type: Boolean,
      default: () => false
    },
    betAmount: {
      type: Number,
      default: () => null
    }
  }
}
</script>
