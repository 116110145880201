<template>
  <v-row>
    <v-col cols="2" class="text-center">
      {{ betType }}
    </v-col>
    <v-col cols="2" class="text-center">
      {{ oddType }} {{ handicap }}
    </v-col>
    <v-col cols="2" class="text-center">
      {{ odd }}
    </v-col>
    <v-col cols="6" class="text-center">
      {{ currency }} {{ amount }}
      <v-icon>mdi-arrow-right-bold</v-icon>
      {{ currency }} {{ moneyCanEarn }}
    </v-col>
  </v-row>
</template>

<script>
import { DEFAULT_CURRENCY } from '../../../../shared/config'
import { round } from '../../../../shared/utils'

export default {
  data: () => ({
    currency: DEFAULT_CURRENCY
  }),
  computed: {
    moneyCanEarn () {
      const earns = this.amount * this.odd
      // if (this.odd > 1) {
      //   earns -= this.amount
      // }
      return round({ number: earns, decimals: 1 })
    }
  },
  props: {
    amount: {
      type: Number,
      default: 0
    },
    odd: {
      type: Number,
      default: 0
    },
    betType: {
      type: String,
      default: null
    },
    oddType: {
      type: String,
      default: null
    },
    handicap: {
      type: Number,
      default: null
    }
  }
}
</script>
