<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        active-class="primary"
      >
        <v-list-item @click="goTo('/')">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goTo('/bets')">
          <v-list-item-title>Apuestas</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { emitter, OPEN_DRAWER } from '../../shared/bus'
export default {
  data: () => ({
    drawer: false
  }),
  mounted () {
    emitter.on(OPEN_DRAWER, this.open)
  },
  beforeDestroy () {
    emitter.off(OPEN_DRAWER, this.open)
  },
  methods: {
    open ({ open = true }) {
      this.drawer = true
    },
    goTo (page) {
      this.$router.push(page)
    }
  }
}
</script>
