<template>
  <div>
    <HomeAppBar />
    <v-container>
      <EventsList />
    </v-container>
    <PlaceBetForm />
  </div>
</template>

<script>
import HomeAppBar from '../components/AppBars/HomeAppBar.vue'
import EventsList from '../components/Events/EventsList.vue'
import PlaceBetForm from '../components/Dialogs/PlaceBetForm.vue'

export default {
  components: {
    HomeAppBar,
    EventsList,
    PlaceBetForm
  }
}
</script>
