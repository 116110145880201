<template>
  <v-card width="100%" :style="`${event.isVisible ? '' : 'opacity: 0.65'}`" :elevation="event.isVisible ? 8 : 1">
    <v-card-text>
      <EventHeader :event="event" />

      <v-row>
        <v-col cols="2">
          <MiniScore :score="event.scores['1']" title="1" />
        </v-col>
        <v-col cols="2">
          <MiniScore :score="event.scores['2']" title="2" />
        </v-col>
        <v-col cols="2">
          <MiniScore :score="event.scores['3']" title="H" />
        </v-col>
        <v-col cols="2">
          <MiniScore :score="event.scores['4']" title="3" />
        </v-col>
        <v-col cols="2">
          <MiniScore :score="event.scores['5']" title="4" />
        </v-col>
        <v-col cols="2">
          <MiniScore :score="event.scores['7']" title="F" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="openEventCharts(event)">cambios</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="openPlaceBetForm(event)">
        Apostar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MiniScore from './MiniScore.vue'
import EventHeader from './EventHeader.vue'
import { emitter, OPEN_BET_PLACE_FORM, OPEN_VIEW_EVENT_CHARTS } from '../../shared/bus'

export default {
  methods: {
    openPlaceBetForm (event) {
      emitter.emit(OPEN_BET_PLACE_FORM, { event })
    },
    openEventCharts (event) {
      emitter.emit(OPEN_VIEW_EVENT_CHARTS, { event })
    }
  },
  props: {
    event: Object,
    default: () => ({
      home: {},
      away: {},
      criteria: {},
      league: {},
      odds: {},
      scores: {},
      timer: {},
      id: null,
      isReadyToBet: false,
      isVisible: false,
      ss: null,
      time: null
    })
  },
  components: {
    MiniScore,
    EventHeader
  }
}
</script>
