// import { multisort } from '../shared/utils'
import { SERVICES_URL } from '../shared/config'

export default {
  async getEvents () {
    let result = []
    try {
      const response = await fetch(`${SERVICES_URL}/inPlayEvents`)
      const body = await response.json()

      // eslint-disable-next-line eqeqeq
      if (body.success == 1) {
        result = body.events
        /*
        result = body.events.sort(multisort([
          {
            prop: 'isReadyToBet',
            direction: 1,
            isNumeric: false
          }
        ]))
        */
      } else {
        console.error('Error en cuerpo de eventos')
      }
    } catch (error) {
      console.error(error)
    }
    return result
  }
}
