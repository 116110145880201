<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <NavigationDrawer />
    <Message />
    <EventCharts />
  </v-app>
</template>

<script>
import NavigationDrawer from './components/AppBars/NavigationDrawer.vue'
import Message from './components/Notifications/Message.vue'
import EventCharts from './components/Dialogs/EventCharts.vue'

export default {
  components: {
    NavigationDrawer,
    Message,
    EventCharts
  }
}
</script>

<style>
.glow-green {
  color: #4CAF50 !important;
  -webkit-animation: glow-green 1s ease-in-out infinite alternate;
  -moz-animation: glow-green 1s ease-in-out infinite alternate;
  animation: glow-green 1s ease-in-out infinite alternate;
}
.glow-yellow {
  color: #FFC107 !important;
  -webkit-animation: glow-yellow 1s ease-in-out infinite alternate;
  -moz-animation: glow-yellow 1s ease-in-out infinite alternate;
  animation: glow-yellow 1s ease-in-out infinite alternate;
}

@keyframes glow-green {
  from {
    text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #00C853, 0 0 4px #00C853, 0 0 5px #00C853, 0 0 6px #00C853, 0 0 7px #00C853;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 3px #2E7D32, 0 0 4px #2E7D32, 0 0 5px #2E7D32, 0 0 6px #2E7D32, 0 0 7px #2E7D32, 0 0 8px #2E7D32;
  }
}
@keyframes glow-yellow {
  from {
    text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #FFD54F, 0 0 4px #FFD54F, 0 0 5px #FFD54F, 0 0 6px #FFD54F, 0 0 7px #FFD54F;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 3px #FFAB00, 0 0 4px #FFAB00, 0 0 5px #FFAB00, 0 0 6px #FFAB00, 0 0 7px #FFAB00, 0 0 8px #FFAB00;
  }
}
</style>
