<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    max-width="450"
    scrollable
    eager
  >
    <v-card>
      <v-card-text class="pt-8">
        <EventHeader :event="event" />
        <v-tabs
          color="accent"
          fixed-tabs
        >
          <v-tab>Half</v-tab>
          <v-tab>Totals</v-tab>

          <v-tab-item eager class="pt-4">
            <canvas id="halfChart" width="200" height="200"></canvas>
          </v-tab-item>
          <v-tab-item eager class="pt-4">
            <canvas id="totalChart" width="200" height="200"></canvas>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions class="text-center">
        <v-btn text @click="dialog = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventHeader from '../Events/EventHeader.vue'

import { emitter, OPEN_VIEW_EVENT_CHARTS } from '../../shared/bus'
import Chart from 'chart.js'

let HALF_CHART_REF
let TOTAL_CHART_REF
function generateNewChart ({ data = [], labels = [], chartId = 'halfChart' }) {
  const chartCanvas = document.getElementById(chartId)
  return new Chart(chartCanvas, {
    type: 'line',
    data: {
      labels,
      datasets: [{
        data,
        fill: false,
        backgroundColor: '#FF9800',
        borderColor: '#FF9800'
      }]
    },
    options: {
      scales: {
        xAxes: [{
          display: false,
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          }
        }]
      },
      legend: {
        display: false
      },
      // Perfermoance: https://www.chartjs.org/docs/latest/general/performance.html
      elements: {
        line: {
          tension: 0 // disables bezier curves
        }
      },
      animation: {
        duration: 0 // general animation time
      },
      hover: {
        animationDuration: 0 // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0 // animation duration after a resize
    }
  })
}

function isValid (value) {
  const result = Number(value)
  if (isNaN(result)) {
    return 0
  }
  return result
}

function prepareData ({ items = [] }) {
  let lastDiff = 0
  let firstHandicap = 0
  const data = []
  const labels = []
  items.forEach((i, $index) => {
    if ($index === 0) {
      firstHandicap = isValid(i.handicap)
    } else {
      if (i.time_str) {
        const validHandicap = isValid(i.handicap)
        const currentDiff = validHandicap - firstHandicap
        if (currentDiff !== lastDiff) {
          lastDiff = currentDiff
          data.push(currentDiff)
          labels.push(`Handicap inicial: ${firstHandicap}\nHandicap actual: ${i.handicap}\nHandicap diff: ${currentDiff}\n\nTimer: ${i.time_str}\nMarcador: ${i.ss}`)
        }
      }
    }
  })
  return { data, labels }
}

export default {
  data: () => ({
    dialog: false,
    event: {
      home: {},
      away: {},
      criteria: {},
      league: {},
      odds: {},
      scores: {},
      timer: {},
      id: null,
      isReadyToBet: false,
      isVisible: false,
      ss: '',
      time: null
    }
  }),
  mounted () {
    // CHART_CANVAS = document.getElementById('halfChart')
    // CHART_CANVAS_CONTEXT = CHART_CANVAS.getContext('2d')
    emitter.on(OPEN_VIEW_EVENT_CHARTS, this.open)
  },
  beforeDestroy () {
    emitter.off(OPEN_VIEW_EVENT_CHARTS, this.open)
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },
    isDark () {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    open ({ event = {} }) {
      this.event = { ...event }
      this.dialog = true

      // half
      if (this.event.odds && this.event.odds.odds_18_6 && this.event.odds.odds_18_6.items) {
        const { data, labels } = prepareData({ items: this.event.odds.odds_18_6.items })
        HALF_CHART_REF = generateNewChart({ data, labels, chartId: 'halfChart' })
      }

      // totals
      if (this.event.odds && this.event.odds.odds_18_3 && this.event.odds.odds_18_3.items) {
        const { data, labels } = prepareData({ items: this.event.odds.odds_18_3.items })
        TOTAL_CHART_REF = generateNewChart({ data, labels, chartId: 'totalChart' })
      }
    }
  },
  watch: {
    dialog (newValue) {
      if (newValue === false) {
        this.event = {
          home: {},
          away: {},
          criteria: {},
          league: {},
          odds: {},
          scores: {},
          timer: {},
          id: null,
          isReadyToBet: false,
          isVisible: false,
          ss: '',
          time: null
        }
        if (HALF_CHART_REF) {
          HALF_CHART_REF.destroy()
        }
        if (TOTAL_CHART_REF) {
          TOTAL_CHART_REF.destroy()
        }
        // CHART_CANVAS_CONTEXT.clearRect(0, 0, CHART_CANVAS.width, CHART_CANVAS.height)
      }
    }
  },
  components: {
    EventHeader
  }
}
</script>
