<template>
  <div class="text-center" v-show="!notStarted">
    <div class="mb-2 font-weight-medium">{{ title }}</div>
    <div>{{ score.home }}</div>

    <div class="mt-2">
      <v-icon small :color="notStarted ? 'grey lighten-1' : (isHomeWinning ? 'green lighten-1' : 'red lighten-1')">mdi-record</v-icon>
    </div>
    <div class="mb-2">
      <v-icon small :color="notStarted ? 'grey lighten-1' : (isAwayWinning ? 'green lighten-1' : 'red lighten-1')">mdi-record</v-icon>
    </div>

    <div>{{ score.away }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    notStarted () {
      // eslint-disable-next-line eqeqeq
      return this.score.home == 0 && this.score.away == 0
    },
    isDrawScore () {
      // eslint-disable-next-line eqeqeq
      return this.notStarted === false && this.score.home == this.score.away
    },
    isAwayWinning () {
      return this.score.home < this.score.away || this.isDrawScore
    },
    isHomeWinning () {
      return this.score.home > this.score.away || this.isDrawScore
    }
  },
  props: {
    score: {
      type: Object,
      default: () => ({
        home: 0,
        away: 0
      })
    },
    title: {
      type: String,
      default: () => ''
    }
  }
}
</script>
