<template>
  <v-row :align="'center'">
    <v-col
      v-for="(bet, $index) in bets"
      :key="$index"
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
    >
      <Bet :bet="bet" />
    </v-col>
  </v-row>
</template>

<script>
import Bet from '../Bets/Bet.vue'
import betsDispatcher from '../../dispatcher/betsDispatcher'
export default {
  data: () => ({
    bets: []
  }),
  mounted () {
    betsDispatcher.getBets()
      .then(response => {
        if (response.data) {
          this.bets = response.data.bets
        } else {
          console.error(response.errors)
        }
      })
      .catch(error => {
        console.error(error)
      })
  },
  components: {
    Bet
  }
}
</script>
