import { makeQuery } from '../shared/hasura'
import { SERVICES_URL } from '../shared/config'

function getEventUrl ({ eventId = '' }) {
  return `${SERVICES_URL}/getEvent/${eventId}`
}

export default {
  getBets () {
    const query = `query get_bet {
      bets {
        id,
        event_id,
        odds_18_3,
        odds_18_6,
        created_at,
        updated_at
      }
    }`
    return makeQuery({
      query,
      variables: {}
    })
  },
  getEventId ({ eventId = '' }) {
    const url = getEventUrl({ eventId })
    return fetch(url).then(response => response.json())
  }
}
