import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Bets from '../views/Bets.vue'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/bets',
      name: 'Bets',
      component: Bets
    }
  ]
})

export default router
