<template>
  <div>
    <v-row no-gutters>
      <v-col cols="8">
        <span class="text-caption">
          <v-icon x-small class="pr-2" :class="isReadyToBet" v-if="event.timer">mdi-record</v-icon>
          {{ event.league.name }}
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="text-right" v-if="event.timer">
        <span style="vertical-align: middle;">{{ event.timer.q }} - {{ event.timer.tm }}:{{ event.timer.ts }} </span>
        <v-icon class="pl-2" size="20">mdi-timer-sand</v-icon>
      </v-col>
    </v-row>

    <v-row :align="'center'" class="my-6" no-gutters>
      <v-col cols="4" class="text-center">
        <span class="text-body-2 font-weight-light">{{ event.home.name }}</span>
      </v-col>
      <v-col cols="4" class="text-center">
        <span class="accent--text">{{ formatScores(event.ss) }}</span>
      </v-col>
      <v-col cols="4" class="text-center">
        <span class="text-body-2 font-weight-light">{{ event.away.name }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    isReadyToBet () {
      return this.event.isReadyToBet ? 'glow-green' : 'glow-yellow'
    }
  },
  methods: {
    formatScores (value = '') {
      return value.replace('-', ' - ')
    }
  },
  props: {
    event: {
      type: Object,
      default: () => ({
        home: {},
        away: {},
        criteria: {},
        league: {},
        odds: {},
        scores: {},
        timer: {},
        id: null,
        isReadyToBet: false,
        isVisible: false,
        ss: '',
        time: null
      })
    }
  }
}
</script>
