export const multisort = ({
  sortBy = [{
    direction: -1,
    isNumeric: false,
    prop: '',
    subProp: ''
  }]
}) => {
  return function innerSort (a, b) {
    let i = 0
    let result = 0
    while (i < sortBy.length && result === 0) {
      const direction = sortBy[i].direction
      let left = sortBy[i].subProp ? a[sortBy[i].prop][sortBy[i].subProp] : a[sortBy[i].prop]
      let right = sortBy[i].subProp ? b[sortBy[i].prop][sortBy[i].subProp] : b[sortBy[i].prop]

      if (sortBy[i].isNumeric === false) {
        left = left.toString()
        right = right.toString()
      }
      result = direction * (left < right ? -1 : (left > right ? 1 : 0))
      i++
    }
    return result
  }
}

export const round = ({ number = 0, decimals = 0 }) =>
  Math.round(number * 10 ** decimals) / (10 ** decimals)
